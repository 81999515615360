import useGraphQLRequest from './useGraphQLRequest';
import { GET_PROFESSIONAL_RATES } from '../graphql/marketPositionRateSettingsQueries';
import { useQuery } from '@tanstack/react-query';

interface IUseWorkHistoriesReturn {
  professionalRates: IProfessionalRate[];
  isLoading: boolean;
}

export interface IProfessionalRate {
  specialty_id: number | null;
  professional_rate: number[];
}

export const useProfessionalRates = ({
  marketId,
  positionId,
}: {
  marketId: number;
  positionId: number;
}): IUseWorkHistoriesReturn => {
  const { request } = useGraphQLRequest();

  // @ts-ignore
  const fetchProfessionalRates = async ({ queryKey: [_entity, market_id, position_id] }) => {
    const { marketPositionRateSettings } = await request(GET_PROFESSIONAL_RATES, {
      input: {
        search: {
          market_id,
          position_id,
        },
      },
    });
    return marketPositionRateSettings;
  };

  const { data, isLoading } = useQuery(
    ['professionalRates', marketId, positionId],
    fetchProfessionalRates,
    { enabled: !!marketId && !!positionId },
  );

  return {
    professionalRates: data ?? [],
    isLoading,
  };
};
