import { IMarket, IPosition } from '@medely/types';
import { GET_ALL_POSITIONS_FOR_MARKETS } from '../graphql';
import { useCurrentUser } from './useCurrentUser';
import { useQuery } from '@tanstack/react-query';
import { useLegacyGraphQLRequest } from './useGraphQLRequest';

interface IUsePositionsReturn {
  positions: IPosition[];
  isLoading: boolean;
}

const useAllPositionsInMarkets = (allKindsPositions = false): IUsePositionsReturn => {
  const { request } = useLegacyGraphQLRequest();
  const { currentUser } = useCurrentUser();
  const enabled = !!currentUser?.professional?.kinds;
  const filterByKinds = allKindsPositions ? undefined : currentUser?.professional?.kinds;

  const getMarketIds = () => {
    const allMarkets: IMarket['id'][] = [];
    currentUser?.professional?.markets.map((market) => {
      allMarkets.push(market.id);
    });

    return allMarkets;
  };

  const fetchPositions = async ({ queryKey: [_entity, kinds] }: { queryKey: any }) => {
    const { positions } = await request(GET_ALL_POSITIONS_FOR_MARKETS, {
      input: { filter: { active: true }, search: { kinds }, orderBy: { display_name: true } },
      market_ids: getMarketIds(),
    });
    return positions;
  };

  const { data, isLoading, isInitialLoading } = useQuery(
    ['positions', filterByKinds],
    fetchPositions,
    {
      enabled,
    },
  );

  return {
    positions: data ?? [],
    isLoading: enabled ? isLoading : isInitialLoading,
  };
};

export default useAllPositionsInMarkets;
