import { yup } from '@medely/ui-kit';
import { MedelyDateTime } from '@medely/date-time-tools';

type ValidateEndDateProps = {
  totalYearsOfExperience: number;
  startDate: Date;
  endDate: Date;
};

type ValidateMissingMonthsEndDateProps = {
  missingMonths: number;
  startDate: Date;
  endDate: Date;
};

export const validateMissingMonthsEndDate = ({
  missingMonths,
  startDate,
  endDate,
}: ValidateMissingMonthsEndDateProps) => {
  const parsedStartDate = new MedelyDateTime(new Date(startDate).toISOString(), { tz: 'system' });
  const parsedEndDate = new MedelyDateTime(new Date(endDate).toISOString(), { tz: 'system' });
  const isEndDateAfterStartDate = parsedEndDate.isAfter(parsedStartDate);

  if (!isEndDateAfterStartDate) {
    return 'End date must be after start date';
  }

  if (!!missingMonths) {
    const today = new Date().toISOString();
    const initialValidEndDate = new MedelyDateTime(today, { tz: 'system' }).minus({
      months: missingMonths,
    });
    const isEndDateBeforeInitialValidDate = parsedEndDate.isBefore(initialValidEndDate);

    if (isEndDateBeforeInitialValidDate) {
      return `Experience must be at least ${missingMonths} month${missingMonths <= 1 ? '' : 's'}`;
    }
  }
  return undefined;
};

export const validateEndDate = ({
  totalYearsOfExperience,
  startDate,
  endDate,
}: ValidateEndDateProps) => {
  const parsedStartDate = new MedelyDateTime(new Date(startDate).toISOString(), { tz: 'system' });
  const parsedEndDate = new MedelyDateTime(new Date(endDate).toISOString(), { tz: 'system' });
  const today = new MedelyDateTime(new Date().toISOString(), { tz: 'system' });
  const isEndDateAfterStartDate = parsedEndDate.isAfter(parsedStartDate);
  const isEndDateInTheFuture = parsedEndDate.isBefore(today);

  if (!isEndDateAfterStartDate) {
    return 'End date must be after start date';
  }

  if (!isEndDateInTheFuture) {
    return 'End date cannot be in the future';
  }

  if (!!totalYearsOfExperience) {
    const allowableWindowForEndOfYoE = Math.max(totalYearsOfExperience / 2, 1.5);
    const today = new Date().toISOString();
    const initialValidEndDate = new MedelyDateTime(today, { tz: 'system' }).minus({
      years: allowableWindowForEndOfYoE,
    });
    const isEndDateBeforeInitialValidDate = parsedEndDate.isBefore(initialValidEndDate);

    if (isEndDateBeforeInitialValidDate) {
      return `Experience must be in the last ${allowableWindowForEndOfYoE} year${
        allowableWindowForEndOfYoE <= 1.5 ? '' : 's'
      }`;
    }
  }
  return undefined;
};

const validateEndDateField = (totalYearsOfExperience: number) =>
  yup.date().test({
    name: 'valid-end-date',
    test(value, ctx) {
      const errorMessage = validateEndDate({
        endDate: ctx.parent.end_date,
        startDate: ctx.parent.start_date,
        totalYearsOfExperience,
      });
      if (!!errorMessage) {
        return ctx.createError({ message: errorMessage });
      }
      return true;
    },
  });

const validateMissingMonthsEndDateField = (missingMonths: number) =>
  yup.date().test({
    name: 'valid-missing-months-end-date',
    test(value, ctx) {
      const errorMessage = validateMissingMonthsEndDate({
        endDate: ctx.parent.end_date,
        startDate: ctx.parent.start_date,
        missingMonths,
      });
      if (!!errorMessage) {
        return ctx.createError({ message: errorMessage });
      }
      return true;
    },
  });

export const createMostRecentWorkExperienceValidation = (totalYearsOfExperience: number) => {
  const workExperienceSchema = yup.object({
    position_id: yup.number().required('Required'),
    location_type_id: yup.number().required('Required'),
    bedside_care: yup.boolean().required('Required'),
    hourly_rate_cents: yup.number().currencyCents(),
    employment_type: yup.number().required('Required'),
    currently_work_here: yup.boolean().default(false),
    start_date: yup.date().required('Required'),
    end_date: yup
      .date()
      .nullable()
      .when('currently_work_here', {
        is: false || undefined,
        then: (schema) =>
          schema.required('Required').concat(validateEndDateField(totalYearsOfExperience)),
      }),
  });

  return workExperienceSchema;
};

export const createWorkExperienceValidation = (missingMonths: number) => {
  const workExperienceSchema = yup.object({
    location_type_id: yup.number().required('Required'),
    bedside_care: yup.boolean().required('Required'),
    hourly_rate_cents: yup.number().currencyCents(),
    employment_type: yup.number().required('Required'),
    currently_work_here: yup.boolean().default(false),
    start_date: yup.date().required('Required'),
    end_date: yup
      .date()
      .nullable()
      .when('currently_work_here', {
        is: false || undefined,
        then: (schema) =>
          schema.required('Required').concat(validateMissingMonthsEndDateField(missingMonths)),
      }),
  });

  return workExperienceSchema;
};
