import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';
import { AxiosResponse } from 'axios';

const useWorkExperienceInfoMutations = (): {
  submitWorkExperienceInfo: UseMutateFunction<AxiosResponse<any>, any, any, unknown>;
} => {
  const { axios } = useNetworkRequest();
  const queryClient = useQueryClient();

  const { mutate: submitWorkExperienceInfo } = useMutation(
    (values) => axios.post(`/v3/professional/work_histories`, values),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['currentUser']);
      },
    },
  );
  return {
    submitWorkExperienceInfo,
  };
};

export default useWorkExperienceInfoMutations;
