import React from 'react';
import { Box, Heading, MultiSelectField, SingleSelectField } from '@medely/ui-kit';
import { YEARS_OPTIONS } from './constants';
import { useWatch } from 'react-hook-form';
import { IPosition } from '@medely/types';
import { SelectedPosition } from './types';

type PositionItemProps = {
  position: Partial<IPosition>;
  positionIndex: number;
};

const PositionItem = ({ position, positionIndex }: PositionItemProps) => {
  const specialtyOptions =
    position?.specialties
      ?.filter(({ active }) => active)
      .map(({ id, label }) => ({ value: id.toString(), label })) || [];

  return (
    <Box mt={2}>
      <Heading
        size="xs"
        color="text.primary"
        testId="clinical-experience-and-specialties-position-title"
      >
        {position?.display_name}
      </Heading>
      <Box>
        <Box mt={2} testId="clinical-experience-and-specialties-single-select-field">
          <SingleSelectField
            name="professional_positions"
            label="Years of experience"
            options={YEARS_OPTIONS}
            parseError={(value) => value.positions[positionIndex]?.years_of_experience}
            parseValue={(value) => value.positions[positionIndex].years_of_experience}
            formatOnChange={(newValue, fieldValue) => {
              const newObject = { ...fieldValue };
              newObject.positions[positionIndex].years_of_experience = newValue;
              return newObject;
            }}
          />
        </Box>
        {!!specialtyOptions.length && (
          <Box mt={2}>
            <MultiSelectField
              name="professional_positions"
              label="Specialties"
              options={specialtyOptions}
              testId="clinical-experience-and-specialties-multi-select"
              parseError={(value) => value.positions[positionIndex]?.specialty_ids}
              parseValue={(value) => value.positions[positionIndex].specialty_ids}
              formatOnChange={(newValue, fieldValue) => {
                const newObject = { ...fieldValue };
                newObject.positions[positionIndex].specialty_ids = newValue;
                return newObject;
              }}
              placeholder=""
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ClinicalExperienceAndSpecialties = ({
  allPositions,
}: {
  allPositions: Partial<IPosition>[];
}) => {
  const professionalPositions = useWatch({ name: 'professional_positions' });
  const selectedPositions: Partial<IPosition>[] =
    professionalPositions?.positions.map((pos: SelectedPosition) =>
      allPositions.find((p) => p.id === pos.id),
    ) || [];

  return (
    <>
      <Heading size="s" color="text.primary" testId="clinical-experience-and-specialties-title">
        Select years of clinical experience and specialties
      </Heading>
      {selectedPositions?.map((position, idx) => (
        <PositionItem key={position.id} position={position} positionIndex={idx} />
      ))}
    </>
  );
};

export default ClinicalExperienceAndSpecialties;
