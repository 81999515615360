import {
  Box,
  Button,
  CheckboxField,
  CurrencyField,
  DateField,
  HStack,
  Label,
  SingleSelectField,
  ToggleButtonGroupField,
  useAnalytics,
  VStack,
} from '@medely/ui-kit';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useProfessionalRates } from '../../../../hooks';
import { findNonSpecialtyProfessionalRate, getMaxHourlyRate, getMinHourlyRate } from '../../util';
import { EMPLOYMENT_TYPE_OPTIONS, PATIENT_CARE_OPTIONS } from './constants';
import { SetPageProps } from './types';
import { FragmentType, graphql, parseFragmentData } from '../../../../graphql/generated';

const WorkExperienceFormCurrentUserGql = graphql(`
  fragment WorkExperienceFormCurrentUser on Account {
    id
    professional {
      markets {
        id
      }
      professional_positions {
        years_of_experience
        position {
          id
        }
      }
    }
  }
`);

type WorkExperienceFormProps = {
  positionsOptions?: {
    value: number;
    label: string;
  }[];
  locationTypeOptions:
    | {
        value: number | undefined;
        label: string | null | undefined;
      }[]
    | undefined;
  currentUser?: FragmentType<typeof WorkExperienceFormCurrentUserGql>;
  positionId?: number;
  hidePositionField?: boolean;
  isModal?: boolean;
  setPage?: (value: SetPageProps) => void;
};

export const WorkExperienceForm = ({
  positionsOptions,
  locationTypeOptions,
  currentUser: inputCurrentUser,
  hidePositionField = false,
  positionId,
  isModal = false,
  setPage,
}: WorkExperienceFormProps) => {
  const currentUser = parseFragmentData(WorkExperienceFormCurrentUserGql, inputCurrentUser);
  const analytics = useAnalytics();
  const selectedPositionId = useWatch({ name: 'position_id' });
  const currentlyWorkHere = useWatch({ name: 'currently_work_here' });

  const parsedPositionId = positionId ?? selectedPositionId;

  // @ts-ignore
  const marketId = currentUser?.professional?.markets[0].id;

  const selectedPositionData = currentUser?.professional?.professional_positions?.find(
    (p: { position: { id: number } }) => p.position.id === parsedPositionId,
  );

  const selectedPositionDataYoE = selectedPositionData?.years_of_experience;

  const { professionalRates } = useProfessionalRates({
    // @ts-ignore
    marketId,
    positionId: parsedPositionId,
  });

  const nonSpecialtyProfessionalRate = findNonSpecialtyProfessionalRate({ professionalRates });
  const minHourlyRate = getMinHourlyRate({ nonSpecialtyProfessionalRate });
  const maxHourlyRate = getMaxHourlyRate({ nonSpecialtyProfessionalRate });

  const handleGoToSummaryClick = () => {
    analytics.track('Product Application - Primary Experience - Viewed', {
      account_id: currentUser?.id,
    });
    if (setPage) {
      setPage('experienceSummary');
    }
  };

  return (
    <>
      {!hidePositionField && (
        <>
          <SingleSelectField
            label={isModal ? 'Position' : 'Most recent position'}
            name="position_id"
            // @ts-ignore
            options={positionsOptions}
            placeholder="Select position"
          />
          <Box py={1} />
        </>
      )}

      {(selectedPositionId || hidePositionField) && (
        <>
          <ToggleButtonGroupField
            label="Did you provide direct patient care?"
            name="bedside_care"
            options={PATIENT_CARE_OPTIONS}
          />
          <Box py={1} />
          <SingleSelectField
            label="Facility type"
            name="location_type_id"
            // @ts-ignore
            options={locationTypeOptions}
            placeholder=""
          />
          <Box py={1} />
          {isModal ? null : (
            <>
              <Label size="s">Hourly rate (optional)</Label>
              <CurrencyField
                name="hourly_rate_cents"
                helperText={`Facilities in your area pay between ${minHourlyRate} - ${maxHourlyRate}.`}
              />
              <Box py={1} />
            </>
          )}
          <SingleSelectField
            label="Employment type"
            name="employment_type"
            options={EMPLOYMENT_TYPE_OPTIONS}
            placeholder=""
          />
          <Box py={1} />
          <CheckboxField
            label="I’m currently working in this position"
            name="currently_work_here"
          />
          <HStack>
            <VStack mr={currentlyWorkHere ? 0 : 1} width="100%">
              <Label size="s">Start date</Label>
              <DateField
                name="start_date"
                helperText={
                  // @ts-ignore
                  selectedPositionDataYoE <= 1 ? 'Exclude new hire training period' : undefined
                }
              />
            </VStack>
            {!currentlyWorkHere && (
              <VStack mr={1} width="100%">
                <Label size="s">End date</Label>
                <DateField name="end_date" />
              </VStack>
            )}
          </HStack>
          {isModal ? null : (
            <HStack py={1} justifyContent="center" alignItems="center">
              <Button variant="text" onClick={() => handleGoToSummaryClick()}>
                I don’t have the required experience
              </Button>
            </HStack>
          )}
          <Box py={1} />
        </>
      )}
    </>
  );
};
