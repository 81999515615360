import React, { useEffect } from 'react';
import { Box, Form, Heading, SubmitButton, Text, useAnalytics } from '@medely/ui-kit';
import { WorkExperienceForm } from '../WorkExperienceForm';
import { useGetWorkExperienceInitialData } from '../useGetWorkExperienceInitialData';
import { LoadingState } from '../../../components/LoadingState';
import { useWorkExperience, ValuesProps } from '../useWorkExperience';
import useWorkExperienceInfoMutations from '../../../../../hooks/useWorkExperienceInfoMutations';
import { createMostRecentWorkExperienceValidation } from '../workExperienceSchema';

type AddExperienceModalInfoProps = {
  onClose: () => void;
};

export const AddExperienceModalInfo = ({ onClose }: AddExperienceModalInfoProps) => {
  const analytics = useAnalytics();
  const { currentUser, currentUserLoading, locationTypeOptions, locationTypesLoading } =
    useGetWorkExperienceInitialData();
  const { positionsOptions, createPayload, totalYearsOfExperience } = useWorkExperience({
    currentUser: currentUser || undefined,
  });
  const { submitWorkExperienceInfo } = useWorkExperienceInfoMutations();

  const baseValuesLoading = currentUserLoading || locationTypesLoading;

  const handleSubmit = (values: ValuesProps) => {
    const parsedValues = createPayload(values);
    submitWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    analytics.track('Product Application - Add Secondary Experience  - Viewed', {
      account_id: currentUser?.id,
    });
  }, []);

  if (baseValuesLoading) {
    return <LoadingState />;
  }

  return (
    <Form
      handleSubmit={handleSubmit}
      schema={createMostRecentWorkExperienceValidation(totalYearsOfExperience)}
    >
      <Box pb={2}>
        <Heading size="s">Add experience</Heading>
        <Text size="m">Provide details for a recent position in the U.S.</Text>
      </Box>

      <WorkExperienceForm
        positionsOptions={positionsOptions}
        locationTypeOptions={locationTypeOptions}
        currentUser={currentUser || undefined}
        isModal={true}
      />
      <SubmitButton fullWidth>Save</SubmitButton>
    </Form>
  );
};
