import { MedelyDateTime } from '@medely/date-time-tools';
import { FragmentType, graphql, parseFragmentData } from '../../../../graphql/generated';

const UseWorkExperienceCurrentUserGql = graphql(`
  fragment UseWorkExperienceCurrentUser on Account {
    professional {
      professional_positions {
        years_of_experience
        position {
          id
          display_name
        }
      }
    }
  }
`);

type UseWorkExperienceProps = {
  currentUser?: FragmentType<typeof UseWorkExperienceCurrentUserGql>;
};

export type ValuesProps = {
  position_id?: number;
  location_type_id: number;
  bedside_care: boolean;
  employment_type: number;
  currently_work_here: boolean;
  start_date: Date;
  end_date: Date;
  hourly_rate_cents: number;
};

type GetMissingMonthsProps = {
  startDate: string;
  endDate?: string;
};

type IsMissingYoEProps = {
  startDate: string;
  endDate?: string;
  positionId: number | undefined;
};

export const useWorkExperience = ({ currentUser: inputCurrentUser }: UseWorkExperienceProps) => {
  const currentUser = parseFragmentData(UseWorkExperienceCurrentUserGql, inputCurrentUser);

  const calculateTotalYearsOfExperience = (): number => {
    let totalYearsOfExperience = 0;
    currentUser?.professional?.professional_positions?.forEach(
      (p) => (totalYearsOfExperience += p.years_of_experience || 0),
    );
    return totalYearsOfExperience;
  };

  const totalYearsOfExperience = calculateTotalYearsOfExperience();

  const getPositionsOptions = () => {
    const options: { value: number; label: string }[] = [];
    currentUser?.professional?.professional_positions?.map((position) => {
      options.push({
        value: position.position.id,
        label: position.position.display_name ?? '',
      });
    });
    return options;
  };

  const positionsOptions = getPositionsOptions();

  // @ts-ignore
  const getInitialWorkExperienceValues = (workHistoryData) => {
    return {
      position_id: workHistoryData?.position?.id,
      // bedside_care: workHistoryData?.bedside_care,
      currently_work_here: workHistoryData?.currently_work_here,
      end_date: workHistoryData?.end_date,
      employment_type: workHistoryData?.employment_type,
      location_type_id: workHistoryData?.location_type_id,
      hourly_rate_cents: workHistoryData?.wage_logs?.hourly_rate_cents,
      start_date: workHistoryData?.start_date,
    };
  };

  const getAddedMonths = ({ startDate, endDate }: GetMissingMonthsProps) => {
    const parsedStartDate = new MedelyDateTime(new Date(startDate).toISOString(), {
      tz: 'system',
    });
    const parsedEndDate = endDate
      ? new MedelyDateTime(new Date(endDate).toISOString(), {
          tz: 'system',
        })
      : new MedelyDateTime(new Date().toISOString(), {
          tz: 'system',
        });
    const missingMonths = parsedStartDate.difference(parsedEndDate, 'months');
    return missingMonths;
  };

  const createPayload = (values: ValuesProps) => {
    const wageLogData = !!values.hourly_rate_cents
      ? {
          wage_log: {
            hourly_rate_cents: values.hourly_rate_cents,
            position_id: values.position_id,
          },
        }
      : null;
    return {
      source: 'application_wizard',
      work_history: {
        clinical: true,
        position_id: values.position_id,
        location_type_id: values.location_type_id,
        bedside_care: values.bedside_care,
        employment_type: values.employment_type,
        currently_work_here: values.currently_work_here,
        start_date: values.start_date,
        end_date: values.end_date,
        ...wageLogData,
      },
    };
  };

  const isMissingYoE = ({ startDate, endDate, positionId }: IsMissingYoEProps) => {
    const initialAddedMonths = getAddedMonths({
      startDate: startDate,
      endDate: endDate,
    });

    const crnPositionId = 25;
    if (crnPositionId === positionId) {
      return initialAddedMonths < 6 ? true : false;
    }
    return initialAddedMonths < 8 ? true : false;
  };

  return {
    totalYearsOfExperience,
    positionsOptions,
    getInitialWorkExperienceValues,
    getAddedMonths,
    createPayload,
    isMissingYoE,
  };
};
