import React, { useEffect } from 'react';
import { Box, Form, Heading, useAnalytics } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../../../components/FixedFooterLayout/FixedFooterLayout';
import { WorkExperienceForm } from './WorkExperienceForm';
import { useApplicationMutations } from '../../../../hooks';
import useWorkExperienceInfoMutations from '../../../../hooks/useWorkExperienceInfoMutations';
import { ApplicationButtons } from '../../components/ApplicationButtons';
import { LoadingState } from '../../components/LoadingState';
import { createMostRecentWorkExperienceValidation } from './workExperienceSchema';
import { useWorkExperience, ValuesProps } from './useWorkExperience';
import { useGetWorkExperienceInitialData } from './useGetWorkExperienceInitialData';
import { SetPageProps } from './types';
import { FragmentType, graphql, parseFragmentData } from '../../../../graphql/generated';

const WorkExperiencePrimaryFormWorkHistoryGql = graphql(`
  fragment WorkExperiencePrimaryFormWorkHistory on WorkHistory {
    id
    currently_work_here
    employment_type
    end_date
    location_type_id
    start_date
    position {
      id
    }
    wage_logs {
      hourly_rate_cents
    }
  }
`);

const WorkExperiencePrimaryFormCurrentUserGql = graphql(`
  fragment WorkExperiencePrimaryFormCurrentUser on Account {
    id
    ...WorkExperienceFormCurrentUser
    ...UseWorkExperienceCurrentUser
  }
`);

type WorkExperiencePrimaryFormProps = {
  setPage: (value: SetPageProps) => void;
  workHistory?: FragmentType<typeof WorkExperiencePrimaryFormWorkHistoryGql>;
  currentUser?: FragmentType<typeof WorkExperiencePrimaryFormCurrentUserGql>;
};

export const WorkExperiencePrimaryForm = ({
  setPage,
  currentUser: inputCurrentUser,
  workHistory: inputWorkHistory,
}: WorkExperiencePrimaryFormProps) => {
  const workHistory = parseFragmentData(WorkExperiencePrimaryFormWorkHistoryGql, inputWorkHistory);

  const currentUser = parseFragmentData(WorkExperiencePrimaryFormCurrentUserGql, inputCurrentUser);

  const analytics = useAnalytics();
  const { locationTypeOptions, locationTypesLoading } = useGetWorkExperienceInitialData();
  const {
    totalYearsOfExperience,
    positionsOptions,
    getInitialWorkExperienceValues,
    createPayload,
    isMissingYoE,
  } = useWorkExperience({ currentUser: currentUser || undefined });
  const { submitWorkExperienceInfo } = useWorkExperienceInfoMutations();
  const { prevStep, isLoading: mutationsLoading } = useApplicationMutations();

  const baseValuesLoading = locationTypesLoading;

  const disableButtons = baseValuesLoading || mutationsLoading;

  const defaultValues = !!workHistory ? getInitialWorkExperienceValues(workHistory) : {};

  const handleSubmit = (values: ValuesProps) => {
    const parsedValues = createPayload(values);
    submitWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        if (
          isMissingYoE({
            startDate: values.start_date.toDateString(),
            endDate: !!values.end_date ? values.end_date.toDateString() : undefined,
            positionId: values.position_id,
          })
        ) {
          setPage('addMoreMonths');
        } else {
          setPage('experienceSummary');
        }
      },
    });
  };

  useEffect(() => {
    analytics.track('Product Application - Primary Experience - Viewed', {
      account_id: currentUser?.id,
    });
  }, []);

  if (baseValuesLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <Form
        handleSubmit={handleSubmit}
        schema={createMostRecentWorkExperienceValidation(totalYearsOfExperience)}
        defaultValues={defaultValues}
      >
        <FixedFooterLayout
          footerContent={<ApplicationButtons disabled={disableButtons} backClick={prevStep} />}
        >
          <Box pb={2}>
            <Heading size="s" color="text.primary">
              Add detail for your most recent paid clinical experience in the U.S.
            </Heading>
          </Box>
          <WorkExperienceForm
            positionsOptions={positionsOptions}
            locationTypeOptions={locationTypeOptions}
            currentUser={currentUser}
            setPage={setPage}
          />
        </FixedFooterLayout>
      </Form>
    </>
  );
};
