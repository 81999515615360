import React, { useEffect } from 'react';
import { LoadingState } from '../../components/LoadingState';
import { Box, Form, Heading, useAnalytics } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../../../components/FixedFooterLayout';
import { ApplicationButtons } from '../../components/ApplicationButtons';
import { useWorkExperience, ValuesProps } from './useWorkExperience';
import { useGetWorkExperienceInitialData } from './useGetWorkExperienceInitialData';
import { createWorkExperienceValidation } from './workExperienceSchema';
import { WorkExperienceForm } from './WorkExperienceForm';
import useWorkExperienceInfoMutations from '../../../../hooks/useWorkExperienceInfoMutations';
import { SetPageProps } from './types';
import { FragmentType, graphql, parseFragmentData } from '../../../../graphql/generated';

const AddMoreMonthsOfWorkExperienceWorkHistoryGql = graphql(`
  fragment AddMoreMonthsOfWorkExperienceWorkHistory on WorkHistory {
    id
    currently_work_here
    employment_type
    end_date
    location_type_id
    start_date
    position {
      id
    }
    wage_logs {
      hourly_rate_cents
    }
  }
`);

const AddMoreMonthsOfWorkExperienceCurrentUserGql = graphql(`
  fragment AddMoreMonthsOfWorkExperienceCurrentUser on Account {
    id
    professional {
      professional_positions {
        has_recent_experience
        position {
          display_name
          id
        }
      }
    }
    ...WorkExperienceFormCurrentUser
    ...UseWorkExperienceCurrentUser
  }
`);

type AddMoreMonthsOfWorkExperienceProps = {
  setPage: (value: SetPageProps) => void;
  workHistory?: FragmentType<typeof AddMoreMonthsOfWorkExperienceWorkHistoryGql>;
  currentUser?: FragmentType<typeof AddMoreMonthsOfWorkExperienceCurrentUserGql>;
};

export const AddMoreMonthsOfWorkExperience = ({
  setPage,
  workHistory: inputWorkHistory,
  currentUser: inputCurrentUser,
}: AddMoreMonthsOfWorkExperienceProps) => {
  const workHistory = parseFragmentData(
    AddMoreMonthsOfWorkExperienceWorkHistoryGql,
    inputWorkHistory,
  );
  const currentUser = parseFragmentData(
    AddMoreMonthsOfWorkExperienceCurrentUserGql,
    inputCurrentUser,
  );

  const analytics = useAnalytics();
  const { locationTypeOptions, locationTypesLoading } = useGetWorkExperienceInitialData();
  const { submitWorkExperienceInfo } = useWorkExperienceInfoMutations();

  const { getAddedMonths, createPayload } = useWorkExperience({ currentUser });

  const baseValuesLoading = locationTypesLoading;

  const initialAddedMonths = !!workHistory
    ? getAddedMonths({
        startDate: workHistory.start_date,
        endDate: workHistory.end_date,
      })
    : 0;

  const recentPositionData = currentUser?.professional?.professional_positions?.find(
    (p) => p.has_recent_experience,
  );

  const positionId = recentPositionData?.position.id;
  const positionName = recentPositionData?.position.display_name;
  const crnPositionId = 25;
  const minMonthsNeeded = positionId === crnPositionId ? 6 : 8;
  const missingMonths = minMonthsNeeded - initialAddedMonths;

  const handleBack = () => {
    setPage('experienceSummary');
  };

  const handleSubmit = (values: ValuesProps) => {
    const parsedValues = createPayload({ ...values, position_id: positionId });
    submitWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        setPage('experienceSummary');
      },
    });
  };

  const disableButtons = baseValuesLoading;

  useEffect(() => {
    analytics.track('Product Application - Add More Experience - Viewed', {
      account_id: currentUser?.id,
    });
  }, []);

  if (baseValuesLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <Form handleSubmit={handleSubmit} schema={createWorkExperienceValidation(initialAddedMonths)}>
        <FixedFooterLayout
          footerContent={<ApplicationButtons disabled={disableButtons} backClick={handleBack} />}
        >
          <Box pb={2}>
            <Heading size="s" color="text.primary">
              Add at least {missingMonths} more months of recent {positionName} experience
            </Heading>
          </Box>
          <WorkExperienceForm
            locationTypeOptions={locationTypeOptions}
            currentUser={currentUser}
            positionId={positionId}
            hidePositionField={true}
            setPage={setPage}
          />
        </FixedFooterLayout>
      </Form>
    </>
  );
};
