import React, { useEffect, useState } from 'react';
import { WorkExperiencePrimaryForm } from './WorkExperiencePrimaryForm';
import { ExperienceSummaryView } from './experienceSummary/ExperienceSummaryView';
import { useGetWorkExperienceInitialData } from './useGetWorkExperienceInitialData';
import { AddMoreMonthsOfWorkExperience } from './AddMoreMonthsOfWorkExperience';
import { useWorkExperience } from './useWorkExperience';
import { LoadingState } from '../../components/LoadingState';
import { SetPageProps } from './types';

export const WorkExperience = () => {
  const [page, setPage] = useState<SetPageProps>('primaryForm');
  const { workHistories, currentUser, currentUserLoading, workHistoryLoading } =
    useGetWorkExperienceInitialData();

  const workHistory = workHistories?.at(0);

  const pages = {
    experienceSummary: <ExperienceSummaryView />,
    primaryForm: (
      <WorkExperiencePrimaryForm
        setPage={setPage}
        workHistory={workHistory}
        currentUser={currentUser || undefined}
      />
    ),
    addMoreMonths: (
      <AddMoreMonthsOfWorkExperience
        setPage={setPage}
        workHistory={workHistory}
        currentUser={currentUser || undefined}
      />
    ),
  };

  const loadingInitialData = currentUserLoading || workHistoryLoading;

  const { isMissingYoE } = useWorkExperience({
    currentUser: currentUser || undefined,
  });

  useEffect(() => {
    const getPage = () => {
      if (!workHistory) {
        return 'primaryForm';
      }
      if (
        isMissingYoE({
          startDate: workHistory.start_date,
          endDate: workHistory.end_date,
          positionId: workHistory?.position?.id,
        })
      ) {
        return 'addMoreMonths';
      }
      return 'experienceSummary';
    };
    if (!loadingInitialData) {
      setPage(getPage());
    }
  }, [currentUser, workHistory]);

  if (loadingInitialData) {
    return <LoadingState />;
  }

  return pages[page];
};
