import { graphql } from '../../../../graphql/generated';
import { useGraphQLQuery } from '../../../../hooks/useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

const LocationTypesGql = graphql(`
  query GetLocationTypesForWorkExperience($input: LocationTypeQueryInput) {
    locationTypes(input: $input) {
      id
      label
    }
  }
`);

const CurrentUserGql = graphql(`
  query GetCurrentUserForWorkExperience($id: Int!, $location_ids: [Int!]!) {
    me {
      id
      professional {
        professional_positions {
          position {
            id
            display_name
          }
          has_recent_experience
          years_of_experience
        }
        markets {
          id
        }
      }
      ...WorkExperiencePrimaryFormCurrentUser
      ...AddMoreMonthsOfWorkExperienceCurrentUser
      ...WorkExperienceFormCurrentUser
      ...UseWorkExperienceCurrentUser
    }
  }
`);

//add bedside_care
const WorkHistoriesGql = graphql(`
  query GetWorkHistoryForWorkExperience($input: WorkHistoryQueryInput) {
    workHistories(input: $input) {
      end_date
      start_date
      position {
        id
      }
      ...WorkExperiencePrimaryFormWorkHistory
      ...AddMoreMonthsOfWorkExperienceWorkHistory
    }
  }
`);

export const useGetWorkExperienceInitialData = () => {
  const { data: locationTypesData, isLoading: locationTypesLoading } = useGraphQLQuery<
    ResultOf<typeof LocationTypesGql>
  >({
    operationName: 'GetLocationTypesForWorkExperience',
    query: LocationTypesGql,
    variables: {
      input: {
        filter: {
          active: true,
          admin_only: false,
        },
        orderBy: {
          rank: true,
        },
      },
    },
  });

  const locationTypes = locationTypesData?.locationTypes;

  const locationTypeOptions = locationTypes?.map((location) => ({
    value: location?.id,
    label: location?.label,
  }));

  const { data: currentUserData, isLoading: currentUserLoading } = useGraphQLQuery<
    ResultOf<typeof CurrentUserGql>
  >({
    operationName: 'GetCurrentUserForWorkExperience',
    query: CurrentUserGql,
    variables: {},
  });

  const currentUser = currentUserData?.me;

  const { data: workHistoryData, isLoading: workHistoryLoading } = useGraphQLQuery<
    ResultOf<typeof WorkHistoriesGql>
  >({
    operationName: 'GetWorkHistoryForWorkExperience',
    query: WorkHistoriesGql,
    variables: {},
  });

  const workHistories = workHistoryData?.workHistories;

  return {
    currentUser,
    locationTypeOptions,
    workHistories,
    locationTypesLoading,
    currentUserLoading,
    workHistoryLoading,
  };
};
