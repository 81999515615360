import { IPosition } from '@medely/types';
import { SelectedPosition } from './steps/positions/types';
import { OptionType } from '@medely/ui-kit';
import uniqBy from 'lodash/uniqBy';
import { IProfessionalRate } from '../../hooks/useProfessionalRates';
import { centsToCurrency } from '@src/utils';

export const getRecommendedPositions = (
  allPositions: IPosition[],
  selectedPositions: SelectedPosition[],
): OptionType[] => {
  let recommendedPositions: OptionType[] = [];
  selectedPositions.forEach((position) => {
    const selectedPosition = allPositions.find((pos) => pos.id === position.id);
    if (!selectedPosition) {
      return;
    }
    const filtered = selectedPosition.recommended_positions.filter(
      (recommendedPosition) =>
        !selectedPositions.some(
          (selectedPosition) => selectedPosition.id === recommendedPosition.id,
        ),
    );
    const showLabel = (recommendedPosition: IPosition) => {
      const getInitialPosition = allPositions.find((p) => p.id === recommendedPosition.id);
      return getInitialPosition?.incrementality === 'prioritize' ? true : false;
    };
    const mapped = filtered.map((recommendedPosition) => ({
      value: recommendedPosition.id,
      label: recommendedPosition.display_name,
      tagProps: showLabel(recommendedPosition) ? { color: 'success', label: 'High demand' } : null,
    }));

    recommendedPositions = recommendedPositions.concat(mapped);
  });
  return uniqBy(recommendedPositions, (p) => p.value).sort((a, b) =>
    a.label.localeCompare(b.label),
  );
};

interface IFindNonSpecialtyProfessionalRateProps {
  professionalRates: IProfessionalRate[];
}

interface IGetMinOrMaxHourlyRateProps {
  nonSpecialtyProfessionalRate: IProfessionalRate;
}

export const findNonSpecialtyProfessionalRate = ({
  professionalRates,
}: // @ts-ignore
IFindNonSpecialtyProfessionalRateProps): IProfessionalRate => {
  if (!!professionalRates.length) {
    return professionalRates.find((rate) => !rate.specialty_id) ?? professionalRates[0];
  }
};

export const getMinHourlyRate = ({
  nonSpecialtyProfessionalRate,
}: IGetMinOrMaxHourlyRateProps): string =>
  nonSpecialtyProfessionalRate && !!nonSpecialtyProfessionalRate?.professional_rate?.length
    ? centsToCurrency(nonSpecialtyProfessionalRate?.professional_rate[0])
    : '$0';

export const getMaxHourlyRate = ({
  nonSpecialtyProfessionalRate,
}: IGetMinOrMaxHourlyRateProps): string =>
  nonSpecialtyProfessionalRate?.professional_rate?.length > 1
    ? centsToCurrency(nonSpecialtyProfessionalRate?.professional_rate[1])
    : '$0';
