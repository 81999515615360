import React from 'react';

import {
  IExpenseAdjustment,
  IJob,
  IJobBilling,
  ILocation,
  IPayout,
  JobStatusEnum,
} from '@medely/types';
import { HStack, Icon, InfoBadge, Label, Text, VStack } from '@medely/ui-kit';
import { useGate } from '../../hooks/useGate';
import {
  centsToCurrency,
  hoursFromJobBillings,
  pluralize,
  splitAmountsByStatus,
} from '../../utils';
import DisplayHelpers from '../../utils/displayHelpers';
import { EarningsJobListItemProps, EarningsJobListItemVariant } from './interface';

const {
  job: { date, shiftType },
} = DisplayHelpers;

export const EarningsJobListItem = ({ variant, job, onClick }: EarningsJobListItemProps) => {
  const { value: isSplitPayEnabled } = useGate('split_pay');
  return (
    <HStack py={2} alignItems="center" justifyContent="space-between" flex={1} onClick={onClick}>
      <VStack gap={0.5}>
        <HStack alignItems="center">
          <Label size="m">{date(job, 'fullDay')}</Label>
          {/*TODO FIN-2796 add weekly OT badge*/}
        </HStack>
        <Text size="m" color="text.secondary">
          {getSubtitle(job)}
        </Text>
      </VStack>
      <HStack ml={2} alignItems="center">
        {getRightSide(variant, job, isSplitPayEnabled)}
        <Icon name="chevron-right" />
      </HStack>
    </HStack>
  );
};

const getSubtitle = (
  job: Pick<IJob, 'current_ends_time' | 'current_starts_time' | 'shift_type'> & {
    expense_adjustments: Pick<IExpenseAdjustment, 'amount_cents'>[];
    job_billings: Pick<IJobBilling, 'total_hours'>[];
    location: Pick<ILocation, 'timezone_lookup'>;
  },
) => {
  const type = shiftType(job, false);
  const hours = hoursFromJobBillings(job.job_billings);
  const numAdj = job.expense_adjustments.length;

  let subtitle = type;
  if (!!hours) {
    subtitle += `・${pluralize(hours, 'hr')}`;
  }
  if (numAdj > 0) {
    subtitle += `・${pluralize(numAdj, 'adjustment')}`;
  }

  return subtitle;
};

const getRightSide = (
  variant: EarningsJobListItemVariant,
  job: Pick<IJob, 'status'> & {
    job_billings: (Pick<IJobBilling, 'id' | 'category' | 'payout_total_amount_cents'> & {
      payout?: Pick<IPayout, 'status'> | null;
      disputed_job_billings?: { id: number }[] | null;
    })[];
    expense_adjustments: (Pick<IExpenseAdjustment, 'amount_cents'> & {
      payout?: Pick<IPayout, 'status'> | null;
    })[];
  },
  isSplitPayEnabled: boolean,
) => {
  let amount = 0;
  let badge = null;
  if (variant === 'pending') {
    const jobBillings = job.job_billings.map((jb) => ({
      ...jb,
      job: { status: job.status },
    }));
    const { disputed, inReview, waitingForPayout } = splitAmountsByStatus(
      jobBillings,
      job.expense_adjustments,
      isSplitPayEnabled,
    );
    amount = disputed + inReview + waitingForPayout;

    if (job.status === JobStatusEnum.Disputed) {
      badge = <InfoBadge color="error" label="Disputed" />;
    } else if (job.status === JobStatusEnum.HeldForDisputeReview) {
      badge = <InfoBadge color="warning" label="In review" />;
    }
  } else {
    // for payout jobs, show the total amount and no badge
    amount =
      job.job_billings.reduce((acc, jb) => acc + jb.payout_total_amount_cents, 0) +
      job.expense_adjustments.reduce((acc, adj) => acc + adj.amount_cents, 0);
  }

  return (
    <VStack gap={0.5}>
      <Text size="m" color="text.secondary" textAlign="right">
        {centsToCurrency(amount)}
      </Text>
      {badge}
    </VStack>
  );
};
