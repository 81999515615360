import isDevOrStaging from './utils/isDevOrStaging';

const config = {
  accountPausedTypeformId: isDevOrStaging() ? 'k3EYha' : 'NJTgkr',
  adminUrl: process.env.REACT_APP_ADMIN_URL || 'http://localhost:5000',
  assignmentReferralTerms: 'https://help.medely.com/en/articles/4809046-assignment-referrals',
  calendlyAssignmentURL: isDevOrStaging()
    ? 'https://calendly.com/development-phone-screening/development-phone-screening-2'
    : 'https://calendly.com/phone-screening/assignments',
  calendlyURL: isDevOrStaging()
    ? 'https://calendly.com/ryan-1525/30min'
    : 'https://calendly.com/phone-screening/phone-screening',
  facilityUrl: process.env.REACT_APP_FACILITY_URL || 'http://localhost:3500',
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyBFXF0RZ_8RFi62eNEnuNzscb8i60JlsQw',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'medely-staging.firebaseapp.com',
  },
  googleMapsKey: process.env.REACT_APP_MAPS_API_KEY ?? 'AIzaSyB1-c4n-lFxIFF9EhCiJUqISOCdwQsVn1o',
  helloSignId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
  help: {
    url: 'https://help.medely.com',
    agencyUrl: 'https://help-partners.medely.com',
    signing_up: 'https://help.medely.com/collections/127521-signing-up',
    covid_requirements:
      'https://help.medely.com/en/articles/5497289-covid-vaccine-booster-or-test-protocol-when-required-by-state-or-facility',
    communityGuidelines: 'https://help.medely.com/en/articles/4564959-community-guidelines',
    communityResources:
      'https://help.medely.com/en/articles/1674135-professional-community-resources',
    sms: 'https://help.medely.com/en/articles/5322046-sms-terms',
    privacyPolicy: 'https://medely.com/privacy-policy',
    clockingInOut: 'https://help.medely.com/en/articles/103312-clocking-in-out',
    frontlineW2Shifts: 'https://intercom.help/frontline-health/en/articles/8464674-w-2-shifts',
  },
  itemUrl: process.env.REACT_APP_ITEM_URL,
  payoutExtensions: ['.jpg', '.png', '.doc', '.docx', '.pdf'],
  professionalTermsPathname: 'https://medely.com/professional_terms',
  rollbar: {
    postClientItemKey:
      process.env.REACT_APP_ROLLBAR_CLIENT_KEY || '020d70f3b088491db3b62035212a8eaf',
  },
  root: '',
  segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  signUpKey: process.env.REACT_APP_SIGNUP_KEY || 'zB9NVETcO9!%',
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  termsOfServiceKey: {
    modal: {
      agency: process.env.REACT_APP_AGENCY_PRO_TOS_MODAL_GROUP_KEY,
      marketplace: process.env.REACT_APP_PRO_TOS_MODAL_GROUP_KEY,
    },
    checkbox: {
      agency: process.env.REACT_APP_AGENCY_PRO_TOS_CHECKBOX_GROUP_KEY,
      marketplace: process.env.REACT_APP_PRO_TOS_CHECKBOX_GROUP_KEY,
    },
  },
  testTypeform: 'p4ayvY',
};

export default config;
